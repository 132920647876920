@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.defaultUserFields {
  margin-top: 20px;
  width: 100%;
  @media (--viewportMedium) {
    margin-top: 41px;
  }
}

.name {
  display: block;
  justify-content: space-between;
  gap: 9px;
  margin-top: 20px;

  @media (--viewportMedium) {
    margin-top: 41px;
    display: flex;
  }
}

.row,
.customFields {
  margin-top: 20px;

  @media (--viewportMedium) {
    margin-top: 41px;
  }
}

.firstNameRoot {
  width: 100%;

  @media (--viewportMedium) {
    width: calc(50% - 4.5px);
  }
}

.lastNameRoot {
  width: 100%;
  margin-top: 24px;

  @media (--viewportMedium) {
    width: calc(50% - 4.5px);
    margin-top: 0px;
  }
}

.password {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
}

/* styling for map form */
.mapSelectBox {
  line-height: 22px;
  font-size: var(--fontSizeButton);
  color: #9ca3af;
  letter-spacing: var(--letterSpacingDescription);
  border: 1px solid #6b7280;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  border-radius: 20px;
  padding: 20px !important;
  padding-bottom: 20px;
  width: 100%;
  @media (--viewportMaxSmall){
    padding: 10px !important;
  }
}

.mapSelectBox p {
  font-size: var(--fontSizeDescription);
  font-weight: 400;
  line-height: var(--lineHeightDescription);
  letter-spacing: var(--letterSpacingDescription);
  text-align: left;
  color: #9ca3af;
  margin: 0;
  padding-top: 17px;
  padding-bottom: 20px;
  font-family: var(--fontFamilyHelmet);
}

.mapSelectBox .map {
  width: calc(100% - 10px);
  height: 271px;
  @media (--viewportMedium) {
    width: 100%;
    max-width: 423px;
  }
}
.mapSelectBox .map canvas {
  @media (--viewportMaxMedium) {
    width: 100% !important;
  }
}
.mapSelectBox .map .mapboxgl-ctrl-geocoder {
  border-radius: 10px;
}
.mapSelectBox .addressContainer {
  width: 230px;
  @media (--viewportExtraSmall) and (--viewportMaxSmall) {
    width: 275px;
  }
  @media (--viewportSmall) and (--viewportMaxMedium) {
    width: 320px;
  }
  @media (--viewportMedium) {
    width: 100%;
  }
}

.mapSelectBox .addressContainer .address {
  color: black;
  font-size: var(--fontSizeSmall);
  line-height: normal;
  padding: 0;
  margin-top: 10px;
  font-family: none;
  text-overflow: ellipsis;
  overflow: hidden;
}

.mapSelectBox .map input {
  padding: 17px 30px;
   font-size: var(--fontSizeSubText);
  height: 36px;
  border-radius: 4px;
  padding: 10px 36px;
  border: none;
  color: black;
  outline: none;
  width: 300px;
  outline: none;
  @media screen and (max-width:375px) {
    width: 135px;
  }
  @media screen and (min-width:376px) and (--viewportMaxExtraSmall) {
    width: 190px;
  }
  @media screen and (--viewportExtraSmall) and (max-width:540px) {
    width: 210px;
  }
}

.mapSelectBox .map ul {
  width: 300px;
  background: white;
  overflow-y: auto;
  border-radius: 8px;
  padding: 2px 14px;
  height: 185px;
  list-style-type: none;
  list-style: none;
  color: var( --colorDarkBlack);
  @media screen and (max-width:375px) {
    width: 135px;
  }
  @media screen and (min-width:376px) and (--viewportMaxExtraSmall) {
    width: 190px;
  }
  @media screen and (--viewportExtraSmall) and (max-width:540px) {
    width: 210px;
  }
}

.mapSelectBox .map ul::-webkit-scrollbar {
  width: 5px;
}

.mapSelectBox .map ul::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.mapSelectBox .map ul::-webkit-scrollbar-thumb {
  background: #888;
}

.mapSelectBox .map ul li {
  white-space: pre-line;
   font-size: var(--fontSizeSubText);
  line-height: normal;
  font-weight: 400;
  margin-bottom: 10px;
}
.mapSelectBox .map ul li div {
  font-size: var(--fontSizeSubText);
  line-height: normal;
  font-weight: 400;
  margin-bottom: 10px;
  @media (--viewportMaxSmall) {
    font-size: var(--fontSizeSmall);
    line-height: normal;
    font-weight: 400; 
  }
}
.mapSelectBox .map ul div {
  font-size: var(--fontSizeSubText);
  line-height: normal;
  font-weight: 400;
  margin-bottom: 10px;
  @media (--viewportMaxSmall) {
    font-size: var(--fontSizeSmall);
    line-height: normal;
    font-weight: 400; 
  }
}

.mapSelectBox .map svg {
  width: 20px;
  height: 20px;
}

.mapSelectBox .map .mapboxgl-ctrl-geocoder--pin-right svg {
  display: none;
}

.mapSelectBox .map button svg {
  fill: black !important;
  position: absolute;
  top: 7px;
  right: 10px;
  left: auto !important;
  width: 16px;
  height: 16px;
}

.mapSelectBox .map button {
  background-color: transparent;
  border: none;
  box-shadow: none;
  width: 27px;
  max-width: 100%;
  height: 36px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position-y: 4px;
}

.uplaodFileContainer {
  width: 100%;
}

.uploadContainer {
  width: 100%;
  border: 1px solid #6b7280;
  height: 56px;
  font-size: var(--fontSizeButton);
  border-radius: var(--borderRadiusButton);
  position: relative;
  display: flex;
  align-items: center;
  @media screen and (--viewportMaxMedium) {
    font-size: var(--fontSizeDescription);
  }
}

.uploadingImagePhoto {
  width: 20px !important;
}

.uploadPreview {
  width: 100%;
  padding: 10px;
  height: 50px;
  display: flex;
  align-items: center;
}

.uploadedImage {
  width: 40px;
  height: 42px;
}

.fileName {
  padding-left: 10px;
  width: calc(100% - 53%);
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: var(--fontSizeSubText);
  @media (--viewportMaxSmall) {
    font-size: var(--fontSizeSmall);
  }
}

.uploadTextLabel {
  font-size: var(--fontSizeDescription);
  font-weight: 400;
  line-height: var(--lineHeightDescription);
  letter-spacing: var(--letterSpacingDescription);
  text-align: left;
  padding: 17px 20px;
  margin: 0;
  color: #9ca3af;
  width: calc(100% - 177px);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @media screen and (max-width:375px) {
    width: 59%;
    padding: 12px;
    font-size: 14px;
  }
  @media screen and (min-width:376px) and (max-width:540px) {
    width: 59%;
    padding: 12px;
    font-size: var(--fontSizeBase);
  }
  @media (--viewportMaxSmall) {
   visibility: hidden;
  }
}

.uploadButton {
  width: 177px;
  height: 54px;
  position: absolute;
  cursor: pointer;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 17px 20px;
  gap: 0px;
  border-radius: 0px 10px 10px 0px;
  border: 0px;
  border-left: 1px solid #000000;
  background: #d9d9d9;
}

.uploadText {
  font-size: var(--fontSizeDescription);
  font-weight: 400;
  line-height: var(--lineHeightDescription);
  letter-spacing: var(--letterSpacingDescription);
  text-align: left;
  color: var( --colorDarkBlack);

  @media screen and (max-width:540px) {
    font-size: 14px;
  }
  @media screen and (min-width:541px) and (--viewportMaxMedium) {
    font-size: var(--fontSizeBase);
  }
}

.signUpButton {
  width: 100%;
  max-width: 356px;
  height: 54px;
  margin: 0 auto;
  margin-top: 72px;
  @media screen and (--viewportMaxMedium) {
    margin-top: 30px;
    max-width: 100%;
  }
}

.signUpButton2 {
  width: 100%;
  max-width: 356px;
  height: 54px;
  margin: 0 auto;
  margin-bottom: 65px;
  margin-top: 65px;

  @media (--viewportMaxLarge) {
    margin-bottom: 30px;
    margin-top: 30px;
  }
  @media (--viewportMaxMedium) {
    max-width: 100%;
  }
}

.signUpButton button {
  border-radius: var(--borderRadiusButton);
  font-family: var(--fontFamilyHelmet);
  font-size: var(--fontSizeButton);
  font-weight: 700;
  line-height: 31px;
  letter-spacing: var(--letterSpacingDescription);
  text-align: center;
  width: 100%;
  max-width: 100% !important;
  border-radius: 30px !important;
  margin-top: 34px;
  transition: all 0.5s ease;
  @media (--viewportMaxMedium) {
    font-size: var(--fontSizeDescription);
    line-height: normal;
  }
}
.signUpButton button:hover {
  color: black;
  border: 1px solid black;
  background-color: white;
}

.signUpButton2 button {
  border-radius: var(--borderRadiusButton);
  font-family: var(--fontFamilyHelmet);
  font-size: var(--fontSizeButton);
  font-weight: 700;
  line-height: 31px;
  letter-spacing: var(--letterSpacingDescription);
  text-align: center;
  width: 100%;
  max-width: 100% !important;
  border-radius: 30px !important;
}

.indiacteRequired {
  font-family: var(--fontFamilyHelmet);
   font-size: var(--fontSizeSubText);
  font-style: italic;
  font-weight: 400;
  line-height: 19.84px;
  letter-spacing: var(--letterSpacingDescription);
  text-align: left;
  color: var( --colorDarkBlack);
  margin-top: 37px;
  margin-bottom: 9px;
}

.customFieldPhoneNumber input::-webkit-outer-spin-button,
.customFieldPhoneNumber input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.customFieldPhoneNumber input[type='number'] {
  -moz-appearance: textfield;
}

.customField {
  margin-top: 41px;

  @media (--viewportMaxMedium) {
    margin-top: 20px;
  }
}

.storeLocation {
  margin-top: 41px;

  @media (--viewportMaxMedium) {
    margin-top: 20px;
  }
}

.textareaField textarea {
  white-space: normal;
}

.textareaField textarea::placeholder {
  color: #9ca3af;
  font-size: var(--fontSizeDescription);
  line-height: 22.23px;
  letter-spacing: 2%;
  white-space: normal;
}

.signUpIndicates {
  font-family: var(--fontFamilyHelmet);
  font-size: var(--fontSizeBase);
  font-style: italic;
  font-weight: 400;
  line-height: 19.84px;
  letter-spacing: var(--letterSpacingDescription);
  text-align: left;
  color: black;
  margin-top: 46px;
  margin-bottom: 61px;

  @media (--viewportMaxLarge) {
    margin-top: 20px;
    margin-bottom: 30px;
  }
}

.storeHoursLabel {
  margin-top: 41px;

  @media (--viewportMaxMedium) {
    margin-top: 20px;
  }
}

.sectionTitle .storeHoursLabel {
  margin-top: 10px;
}

.uploadAvatarWrapper {
  margin-top: 41px;

  @media (--viewportMaxMedium) {
    margin-top: 20px;
  }
}
.uploadAvatarWrapper label div {
  padding: 0;
  font-family: var(--fontFamilyHelmet);
  font-size: var(--fontSizeDescription);
  font-weight: 700;
  line-height: var(--lineHeightDescription);
  letter-spacing: var(--letterSpacingDescription);
  text-align: left;
  color: var( --colorDarkBlack);
  margin-bottom: 12px;

  @media (--viewportMaxMedium) {
    font-size: var(--fontSizeBase) !important;
    line-height: normal;
  }
}
.error {
  margin-top: 6px;
  margin-bottom: 0;
  color: var(--colorFail);
  overflow: hidden;
  font-size: var(--fontSizeSubText);
  line-height: var(--lineHeightBase);
  font-weight: 700;

  @media (--viewportMedium) {
    margin-top: 8px;
  }
}
.imageDimension {
  font-size: var(--fontSizeSubText);
  font-weight: 400;
  font-family: var(--fontFamilyHelmet);
  margin: 0;
  margin-top: 4px;
}