@import '../../../styles/customMediaQueries.css';

.root {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.sectionContainer {
  padding: 0;
  margin-bottom: 36px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 40px;
  }
}

.profileImageAndStoreLogoContainer {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding: 0;
  margin: 0;

  @media (--viewportMaxMedium) {
    flex-direction: column;
    justify-content: center;
  }
}

.profileImageAndStoreLogoContainer label {
  margin: 0 auto;
  padding: 0;
}

/* Store Logo */
.profileSectionMain {
  font-family: var(--fontFamilyHelmet);
  width: 50%;

  @media (--viewportMaxMedium) {
    width: 100%;
  }
}

.profileSectionMain img {
  width: 100%;
}

.profileSectionMain h2 {
  text-align: center;
}

.sectionTitle {
  font-size: var(--fontSizeDescription);
  font-weight: 700;
  line-height: 21.78px;
  color: var(--colorDarkBlack);
  margin-top: 40px;
}

.customField label {
  font-size: var(--fontSizeDescription);
  font-weight: 700;
  line-height: 21.78px;
  color: var(--colorDarkBlack);
  margin-top: 40px;
  padding: 0;
  margin-bottom: 10px;
  font-family: var(--fontFamilyHelmet);
  @media (--viewportMaxMedium) {
    font-size: var(--fontSizeBase);
    line-height: normal;
  }
}

.customField input {
  border: 1px solid #6b7280;
  background: transparent;
  width: 100%;
  height: 56px;
  border-radius: var(--borderRadiusButton);
  box-shadow: none;
  outline: none;
  padding: 17px 20px;
  font-family: var(--fontFamilyHelmet);
  font-size: var(--fontSizeButton);
  font-weight: 400;
  line-height: var(--lineHeightDescription);
  letter-spacing: var(--letterSpacingDescription);
  text-align: left;

  @media (--viewportMaxMedium) {
    font-size: var(--fontSizeBase);
    line-height: normal;
  }
}

.customField textarea {
  border: 1px solid #6b7280;
  background: transparent;
  width: 100%;
  height: 56px;
  border-radius: var(--borderRadiusButton);
  box-shadow: none;
  outline: none;
  padding: 17px 20px;
  font-family: var(--fontFamilyHelmet);
  font-size: var(--fontSizeButton);
  font-weight: 400;
  line-height: var(--lineHeightDescription);
  letter-spacing: var(--letterSpacingDescription);
  text-align: left;

  @media (--viewportMaxMedium) {
    font-size: var(--fontSizeBase);
    line-height: normal;
  }
}

.sectionTitle .storeHoursLabel {
  margin-top: 10px;
}

.sectionTitle span {
  font-size: var(--fontSizeButton);
  font-weight: 700;
  line-height: 24.2px;
  text-align: left;
  font-family: var(--fontFamilyHelmet);
}

.lastSection {
  margin-bottom: 69px;

  @media (--viewportMedium) {
    margin-bottom: 111px;

    & .sectionTitle {
      margin-bottom: 16px;
    }
  }
}

.uploadAvatarInput {
  display: none;
}

.uploadAvatarWrapper {
  margin-top: 18px;
  margin-bottom: 18px;
  position: relative;

  @media (--viewportMedium) {
    margin-top: 18px;
    margin-bottom: 16px;
    position: relative;
  }
}

.uploadAvatarWrapper label {
  width: 250px;
  margin: 0 auto;
}

.label {
  width: var(--ProfileSettingsForm_avatarSize);
  position: relative;

  @media (--viewportMedium) {
    width: var(--ProfileSettingsForm_avatarSizeDesktop);
    position: relative;
  }
}

.firstName input {
  border: 1px solid #9ca3af;
  border-radius: 6px;
  padding: 10px 18px;
}

.firstName label {
  font-family: var(--fontFamilyHelmet);
}

.lastName label {
  font-family: var(--fontFamilyHelmet);
}

.lastName input {
  border: 1px solid #9ca3af;
  border-radius: 6px;
  padding: 10px 18px;
}

.uploadContainer {
  position: absolute;
  top: -3px;
  left: -3px;
  display: flex;
  justify-content: center;
  font-family: var(--fontFamilyHelmet);
  width: 100%;
  height: 255px;
  align-items: center;
  border-radius: 100%;
}

.storeContainer {
  position: relative;
  width: 100%;
  height: 233px;
  display: flex;
  justify-content: center;
  font-family: var(--fontFamilyHelmet);
}

.storePreview {
  width: 100%;
  height: 233px;
}

.storePreview p {
  margin: 0;
  padding: 0;
  display: none;
}

.uploadPreview {
  width: 100%;
  height: 250px;
  border-radius: 100%;
}

.storePreview img {
  width: 100%;
  height: 233px;
  object-fit: cover;
}

.uploadPreview img {
  width: 100%;
  height: 250px;
  border-radius: 100%;
}

.uploadPreview p {
  margin: 0;
  padding: 0;
  color: var(--colorGrey300);
  margin-top: 23px;
  font-family: var(--fontFamilyHelmet);

  @media (--viewportMedium) {
    margin-top: 43px;
    margin-left: 38px;
  }
}

.uploadButton {
  width: 97.09px;
  height: 38.1px;
  background-color: hsl(0 0% 100%);
  border: solid 1px var(--colorGrey100);
  border-radius: 2px;
  display: flex;
  align-items: center;
  padding: 7px 10px;
  font-size: var(--fontSizeSubText);
}

.storeContainer .changeAvatar p {
  margin: 0;
  padding: 0;
  font-size: var(--fontSizeSubText);
  position: relative;
  top: 1px;
  font-family: var(--fontFamilyHelmet);
}

.storeContainer .changeAvatar {
  bottom: 22px;
}

.storeContainer .fileInfo {
  margin-left: 0;
}

.uploadContainer .changeAvatar p {
  margin: 0;
  padding: 0;
  font-size: var(--fontSizeSubText);
  position: relative;
  top: 1px;
  font-family: var(--fontFamilyHelmet);
}

.avatarPlaceholder,
.avatarContainer {
  /* Dimension */
  position: relative;
  width: 100%;
  height: 250px;

  /* Center content */
  display: flex;
  align-items: center;
  justify-content: center;

  /* Initial coloring */
  background-color: var(--colorGrey50);
  border-radius: 100%;
  cursor: pointer;

  @media (--viewportMedium) {
    width: var(--ProfileSettingsForm_avatarSizeDesktop);
    height: 250px;
    border-radius: calc(var(--ProfileSettingsForm_avatarSizeDesktop) / 2);
  }
}

.avatarPlaceholder {
  /* Placeholder border */
  border-style: dashed;
  border-color: var(--colorGrey100);
  border-width: 2px;

  transition: var(--transitionStyleButton);

  &:hover {
    border-color: var(--colorGrey300);
  }
}

.avatarPlaceholderTextMobile {
  @media (--viewportMedium) {
    display: none;
  }
}

.avatarPlaceholderText {
  display: none;

  @media (--viewportMedium) {
    display: block;
    max-width: 130px;
    text-align: center;
  }
}

.avatarUploadError {
  /* Placeholder border */
  border-style: dashed;
  border-color: var(--colorFail);
  border-width: 2px;
}

.error {
  /* Font */
  composes: h4 from global;
  color: var(--colorFail);
  margin-top: 18px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 22px;
    margin-bottom: 2px;
  }
}

.avatar {
  width: 100%;
  height: 100%;
}

.changeAvatar {
  /* Font */
  composes: h5 from global;
  font-weight: var(--fontWeightMedium);

  /* Positioning: right */
  position: absolute;
  font-weight: var(--fontWeightMedium);
  position: absolute;
  bottom: 27px;
  height: 38px;
  font-family: var(--fontFamilyHelmet);
  background-color: var(--colorWhite);
  background-image: url('data:image/svg+xml;utf8,<svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"><g stroke="%234A4A4A" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><path d="M5.307 11.155L1 13l1.846-4.308L10.54 1 13 3.46zM11 5L9 3M5 11L3 9"/></g></svg>');
  background-repeat: no-repeat;
  background-position: 13px 12px;
  border: solid 1px var(--colorGrey100);
  border-radius: 2px;
  margin-top: 0;
  margin-bottom: 0;
  cursor: pointer;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  padding-bottom: 2px;
  padding-left: 17px;

  @media (--viewportMedium) {
    /* Position: under */
    bottom: -10px;
    right: auto;
    margin-top: 0;
    margin-bottom: 0;
    transition: var(--transitionStyleButton);
    cursor: pointer;
  }

  &:hover {
    border: solid 1px var(--colorGrey300);
  }
}

.uploadingImagePhoto {
  width: 20px !important;
}

.uploadingImage {
  /* Dimensions */
  width: var(--ProfileSettingsForm_avatarSize);
  height: var(--ProfileSettingsForm_avatarSize);

  /* Image fitted to container */
  object-fit: cover;
  background-color: var(--colorGrey100);
  /* Loading BG color */
  border-radius: calc(var(--ProfileSettingsForm_avatarSize) / 2);
  overflow: hidden;

  display: block;
  position: relative;
  margin: 0;

  @media (--viewportMedium) {
    width: var(--ProfileSettingsForm_avatarSizeDesktop);
    height: var(--ProfileSettingsForm_avatarSizeDesktop);
    border-radius: calc(var(--ProfileSettingsForm_avatarSizeDesktop) / 2);
  }
}

.uploadingImageOverlay {
  /* Cover everything (overlay) */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  /* Overlay style */
  background-color: var(--colorWhite);
  opacity: 0.8;

  /* Center content */
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatarInvisible {
  visibility: hidden;
  position: absolute;
  top: -1000px;
  left: -1000px;
}

.tip {
  composes: marketplaceDefaultFontStyles from global;
  color: var(--colorGrey300);
  margin-top: 0;
  margin-bottom: 12px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.fileInfo {
  margin: 0;
  padding: 0;
  color: var(--colorGrey300);
  margin-top: 23px;
  font-family: var(--fontFamilyHelmet);
  text-align: center;

  @media (--viewportMedium) {
    margin-top: 43px;
    margin-left: 38px;
  }
}

.storeInfo {
  margin: 0;
  padding: 0;
  color: var(--colorGrey300);
  margin-top: 12px;
  font-family: var(--fontFamilyHelmet);

  @media (--viewportMedium) {
    margin-top: 15px;
  }
}

.nameContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;

  @media (--viewportMedium) {
    margin-top: 24px;
  }
  @media screen and (max-width: 525px) {
    flex-direction: column;
    gap: 18px;
  }
}

.row {
  width: 100%;
}

.firstName {
  width: calc(34% - 9px);
  @media screen and (max-width: 525px) {
    width: 100%;
  }
}

.lastName {
  width: calc(66% - 9px);
  @media screen and (max-width: 525px) {
    width: 100%;
  }
}

.extraInfo {
  composes: textSmall from global;
  color: var(--colorGrey300);
  margin-top: 11px;

  @media (--viewportMedium) {
    margin-top: 16px;
  }
}

.submitButton {
  margin-top: 24px;
  margin-bottom: 56px;
  font-family: var(--fontFamilyHelmet);
  background-color: var(--colorLightYellow) !important;
  border: 1px solid black;
  color: black;
  font-family: var(--fontFamilyHelmet);
  font-size: var(--fontSizeButton);
  font-weight: 700;
  line-height: 27.28px;
  letter-spacing: var(--letterSpacingDescription);
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  border-radius: var(--borderRadiusButton);
  @media (--viewportMaxMedium) {
    font-size: var(--fontSizeDescription);
    line-height: normal;
  }
  transition: all 0.5s ease;
}
.submitButton:hover {
  color: black;
  background-color: white !important;
  border: 1px solid black;
}

.storeLocation {
  margin-top: 10px;

  @media (--viewportMaxMedium) {
    margin-top: 20px;
  }
}

.row,
.customFields {
  margin-top: 20px;

  @media (--viewportMedium) {
    margin-top: 20px;
  }
}

.customField {
  margin-top: 20px;
}

.sectionContainer input {
  border: 1px solid #6b7280;
  background: transparent;
  width: 100%;
  height: 56px;
  border-radius: var(--borderRadiusButton);
  box-shadow: none;
  outline: none;
  padding: 17px 20px;
  font-family: var(--fontFamilyHelmet);
  font-size: var(--fontSizeButton);
  font-weight: 400;
  line-height: var(--lineHeightDescription);
  letter-spacing: var(--letterSpacingDescription);
  text-align: left;

  @media (--viewportMaxMedium) {
    font-size: var(--fontSizeBase);
    line-height: normal;
  }
}

/* styling for map form */
.mapSelectBox {
  line-height: 22px;
  font-size: var(--fontSizeButton);
  color: #9ca3af;
  letter-spacing: var(--letterSpacingDescription);
  border: 1px solid #6b7280;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  border-radius: 20px;
  padding: 20px 20px;
  @media screen and (max-width: 525px) {
    width: 100%;
  }
  @media (--viewportMaxMedium) {
    font-size: var(--fontSizeDescription);
  }
}

.mapSelectBox p {
  font-size: var(--fontSizeDescription);
  font-weight: 400;
  line-height: var(--lineHeightDescription);
  letter-spacing: var(--letterSpacingDescription);
  text-align: left;
  color: #9ca3af;
  margin: 0;
  padding-top: 17px;
  padding-bottom: 20px;
  font-family: var(--fontFamilyHelmet);
}

.mapSelectBox .map {
  width: 100%;
  max-width: 452px;
  height: 271px;
  @media screen and (max-width) {
  }
}

.mapSelectBox .map .mapboxgl-ctrl-geocoder {
  border-radius: 10px;
}

.mapSelectBox .address {
  color: black;
  font-size: var(--fontSizeSmall);
  line-height: normal;
  padding: 0;
  margin-top: 10px;
  font-family: none;
  white-space: normal;
}

.mapSelectBox .map input {
  padding: 17px 30px;
  font-size: var(--fontSizeSubText);
  height: 36px;
  border-radius: 4px;
  padding: 10px 36px;
  border: none;
  color: black;
  outline: none;
  width: 300px;
  outline: none;
  @media screen and (max-width: 375px) {
    width: 135px;
  }
  @media screen and (min-width: 376px) and (--viewportMaxExtraSmall) {
    width: 190px;
  }
  @media screen and (--viewportExtraSmall) and (max-width: 540px) {
    width: 210px;
  }
}

.mapSelectBox .map ul {
  width: 300px;
  background: white;
  overflow-y: auto;
  border-radius: 8px;
  padding: 2px 24px;
  height: 185px;
  list-style-type: circle;
  list-style: circle;
  color: var(--colorDarkBlack);
  @media screen and (max-width: 375px) {
    width: 135px;
  }
  @media screen and (min-width: 376px) and (--viewportMaxExtraSmall) {
    width: 190px;
  }
  @media screen and (--viewportExtraSmall) and (max-width: 540px) {
    width: 210px;
  }
}

.mapSelectBox .map ul::-webkit-scrollbar {
  width: 5px;
}

.mapSelectBox .map ul::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.mapSelectBox .map ul::-webkit-scrollbar-thumb {
  background: #888;
}

.mapSelectBox .map ul li {
  white-space: pre-line;
  font-size: var(--fontSizeSubText);
  line-height: normal;
  font-weight: 400;
}

.mapSelectBox .map svg {
  fill: black !important;
  width: 20px;
  height: 20px;
}

.mapSelectBox .map .mapboxgl-ctrl-geocoder--pin-right svg {
  display: none;
}

.mapSelectBox .map button svg {
  fill: black !important;
  position: absolute;
  top: 7px;
  right: 10px;
  left: auto !important;
  width: 16px;
  height: 16px;
}

.mapSelectBox .map button {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
