@import url('../../styles/customMediaQueries.css');

.hoursMain {
  font-size: var(--fontSizeDescription);
  color: #9ca3af;
  letter-spacing: var(--letterSpacingDescription);
  border: 1px solid #6b7280;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  border-radius: 20px;
  padding: 0 20px;
  padding-bottom: 20px;
  position: relative;
  margin-bottom: 41px;
  width: 100%;

  @media (--viewportMaxMedium) {
    margin-bottom: 20px;
    padding: 0 12px;
  }
}

.errorContainer li {
  margin-bottom: 0;
  color: var(--colorFail);
  overflow: hidden;
  font-size: 17px;
  list-style: none;
  margin-bottom: 15px;
}

.storeHours {
  margin-top: 24px;
}

.hoursContainer {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  padding: 0 17px;
  @media screen and (max-width: 525px) {
    flex-wrap: wrap;
    gap: 8px;
  }
  @media screen and (max-width:375px) {
    padding: 0 6px;
  }
}

.hoursSelectText {
  font-size: var(--fontSizeDescription);
  font-weight: 400;
  line-height: var(--lineHeightDescription);
  letter-spacing: var(--letterSpacingDescription);
  text-align: left;
  color: #9ca3af;
  margin: 0;
  padding-top: 17px;
  padding-bottom: 15px;
  font-family: var(--fontFamilyHelmet);
}

.hoursMain input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.containerHours {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 0px;
  margin-bottom: 12px;
  cursor: pointer;
   font-size: var(--fontSizeSubHeading);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
   font-size: var(--fontSizeSubText) !important;
  font-weight: 400 !important;
  line-height: 17.36px !important;
  letter-spacing: var(--letterSpacingDescription);
  text-align: left;
  width: 100%;
  max-width: 126px;
  margin-bottom: 0px !important;
  padding-top: 0px;
  @media (--viewportMaxMedium) {
    padding-top: 3px;
  }
}

.containerHours p {
  margin: 0;
  padding: 0;
  padding-left: 29px;
  font-family: var(--fontFamilyHelmet);
  font-size: var(--fontSizeSubText);
}

/* Hide the browser's default checkbox */
.containerHours input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 3px;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: var( --colorWhite);
  border: 1px solid black;
}

/* On mouse-over, add a grey background color */
.containerHours:hover input ~ .checkmark {
  background-color: var( --colorWhite);
}

/* When the checkbox is checked, add a blue background */
.containerHours input:checked ~ .checkmark {
  background-color: black;
  border-radius: 3px;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.containerHours input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.containerHours .checkmark:after {
  left: 5px;
  top: 1px;
  width: 6px;
  height: 11px;
  border: 1px solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  display: none;
}

.hoursDurationMain {
  display: flex;
  gap: 6px;
  align-items: center;
}

.hoursDuration {
   font-size: var(--fontSizeSubText);
  font-weight: 400;
  color: var( --colorDarkBlack);
  margin: 0;
  margin-right: 2px;
  padding: 0;
  font-family: var(--fontFamilyHelmet);
}
.hoursSelectMain {
  display: block;
}
.hoursSelectMain .hoursSelectBox {
  appearance: none;
  outline: 10px red;
  border: 0;
  box-shadow: none;

  flex: 1;
  padding: 0 1em;
  color: var( --colorWhite);
  background-color: var(--darkgray);
  background-image: none;
  cursor: pointer;
  padding: 0 !important;
  color: var( --colorDarkBlack);
  letter-spacing: var(--letterSpacingDescription);
  border: 1px solid #6b7280;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  border-radius: 0px !important;
  height: 20px !important;
  line-height: 20px !important;
  font-size: 10px !important;
  padding: 0px 6px !important;
}

.hoursSelectMain .hoursSelectBox::-ms-expand {
  display: none;
}

.hoursSelect {
  position: relative;
  display: flex;
  width: 66px;
  height: 20px !important;
  line-height: 20px !important;
  font-size: 10px !important;
  overflow: hidden;
}

.hoursSelect::after {
  content: '';
  position: absolute;
  top: 50%;
  width: 6px;
  height: 6px;
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  background-color: transparent;
  transition: 0.25s all ease;
  pointer-events: none;
  position: absolute;
  right: 8px;
  top: 4px;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var( --colorDarkBlack);
}

/* Transition */
.hoursSelect:hover::after {
  color: var( --colorDarkBlack);
}

.hoursBorder {
  border: 1px solid #9ca3af;
  width: 100%;
  max-width: 433.01px;
}

.choiseTimeHead {
  color: var( --colorDarkBlack);
  font-size: var(--fontSizeDescription);
  font-weight: 400;
  line-height: var(--lineHeightDescription);
  letter-spacing: var(--letterSpacingDescription);
  text-align: left;
  padding: 0;
  margin: 0;
  margin-top: 20px;
  margin-bottom: 15px;
  font-family: var(--fontFamilyHelmet);
  @media screen and (max-width: 767px) {
     font-size: var(--fontSizeSubText);
    line-height: normal;
  }
}

.choiseTimeData {
  color: var( --colorDarkBlack);
  font-size: var(--fontSizeDescription);
  font-weight: 400;
  line-height: var(--lineHeightDescription);
  letter-spacing: var(--letterSpacingDescription);
  text-align: left;
  padding: 0;
  margin: 10px 0;
  font-family: var(--fontFamilyHelmet);
  @media screen and (max-width: 767px) {
     font-size: var(--fontSizeSubText);
    line-height: normal;
  }
}
.hoursTextContainer {
  padding: 0 8px !important;
  @media screen and (max-width:375px) {
    padding: 0px !important;
  }
}
